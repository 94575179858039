import React from "react";
import './Home.css'
import mine from '../../assets/image/mine.png'
import Linkedin from '@iconscout/react-unicons/icons/uil-linkedin'
import Github from '@iconscout/react-unicons/icons/uil-github'
import { HiOutlineMail } from "react-icons/hi"
import { Email } from "@mui/icons-material";
import { Link } from "react-scroll";

const Home = () => {
    return (
<div className="home" id="Home">
  <div className="home-left">
    <div className="home-detail">
      <span> Hello, I am Feven Seifu</span>
      <span>I'm a software Engineer.</span>
      {/* <span> An experienced full-stack developer with different languages including JavaScript technologies (MERN Stack) and Django. </span> */}
  </div>
  <Link spy={true} to='About' smooth={true} >
  {/* <button className="home-btn">
    Learn More
  </button> */}
  </Link>
  <div className="logo">
  <div className="logo1">
    <a href="https://www.linkedin.com/in/feven-seifu/" >
    <Linkedin color='blue' size='4rem'/>
    </a>
    </div>
    <div className="logo2">
    <a href="https://github.com/Feven98">
    <Github color='black' size='4rem'/>
    </a>
    {/* <a  href="mailto:rutemikey1@gmail.com">
    <Email size={50}/>
    </a> */}
   </div>
   <div className="logo3">
    <a
              className="flex justify-between items-center w-full text-gray-300"
              href="mailto:rutemikey1@gmail.com"
            >
            <HiOutlineMail size={70}/>
            </a>
  </div>
  </div>
  </div>
  {/* <div className="home-right">
    <img src={mine} alt="image"/>
  </div> */}
</div>
    )
  }
  
  export default Home;